<template>
  <div class="main">
    <div class="header">
      <div class="navs">{{pop}} / {{child}}</div>
      <div class="conter"></div>
    </div>
    <div class="body">
      <div class="navs">
        <div class="nav" v-for="(item, index) in navs" :key="index">
          <div class="pop">{{item.title}}</div>
          <div class="child">
            <div class="btn" :class="check == nav.val ? 'sel' : ''" v-for="(nav, keys) in item.child" :key="keys" @click="godel(nav.val)">{{nav.text}}</div>
          </div>
        </div>
      </div>
      <div class="conter">
        <div class="title">{{conter.title}}</div>
        <div class="items" :name="item.tab" v-for="(item, index) in conter.items" :key="index">
          <div class="th">{{item.title}}</div>
          <div class="td" v-for="(it,ins) in item.text" :key="ins">{{it}}</div>
          <div class="tarea" v-for="(ta, int) in item.textarea" :key="int">{{ta}}</div>
          <img :src="item.img" alt="" v-show="item.img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const items = require('@/utils/help.json')
  export default {
    name: 'index',
    data () {
      return {
        navs: [
          {
            title: '关于我们',
            child: [
              // {
              //   text: '品牌简介',
              //   val: 'brand'
              // },
              {
                text: '市场合作',
                val: 'market'
              }
            ]
          },
          {
            title: '购物指南',
            child: [
              {
                text: '定金规则',
                val: 'depositRules'
              },
              {
                text: '定制时间',
                val: 'customTime'
              },
              {
                text: '配送方法及收费',
                val: 'deliveryMethod'
              }
            ]
          },
          {
            title: '支付与物流',
            child: [
              // {
              //   text: '在线支付',
              //   val: 'onlinePayment'
              // },
              {
                text: '银行转账',
                val: 'bankTransfer'
              },
              {
                text: '支付常见问题',
                val: 'paymentProblems'
              }
            ]
          },
          {
            title: '售后服务',
            child: [
              // {
              //   text: '15天退换货',
              //   val: 'daysReturn'
              // },
              {
                text: '售后服务',
                val: 'afterSalesService'
              },
              {
                text: '购物协议',
                val: 'shoppingAgreement'
              },
              {
                text: '质量承诺',
                val: 'qualityPromise'
              }
            ]
          }
        ],
        check: 'brand',
        pop: '',
        child: '',
        conter: ''
      }
    },
    watch: {
      $route (to, from) {
        console.log(to)
        if (to.path != '/service') return
        if (!to.query.t) return
        let t = to.query.t
        if (t == this.check) return
        else {
          this.changeNav(t)
        }
      }
    },
    mounted () {
      let nav = this.$route.query.t
      this.changeNav(nav)
    },
    methods: {
      getInit () {
        this.conter = items.market
      },
      changeNav(nav) {
        console.log('change',items);
        this.conter = ''
        switch (nav) {
          case 'barnd':
            this.conter = items.barnd
            break;
          case 'market':
            this.conter = items.market
            break;
          case 'depositRules':
            this.conter = items.depositRules
            break;
          case 'customTime':
            this.conter = items.customTime
            break;
          case 'deliveryMethod':
            this.conter = items.deliveryMethod
            break;
          case 'onlinePayment':
            this.conter = items.onlinePayment
            break;
          case 'bankTransfer':
            this.conter = items.bankTransfer
            break;
          case 'paymentProblems':
            this.conter = items.paymentProblems
            break;
          case 'daysReturn':
            this.conter = items.daysReturn
            break;
          case 'afterSalesService':
            this.conter = items.afterSalesService
            break;
          case 'shoppingAgreement':
            this.conter = items.shoppingAgreement
            break;
          case 'qualityPromise':
            this.conter = items.qualityPromise
            break;
        }
        this.changeSel(nav)
      },
      changeSel(nav) {
        console.log('sel',nav)
        this.check = nav
        let navs = this.navs;
        let arr = navs.map((item, index) => {
          console.log(item)
          item.child.map((t, i) => {
            console.log(t)
            if (nav == t.val) {
              this.pop = item.title
              this.child = t.text
            }
          })
        })
      },
      godel(val) {
        this.$router.push({
          path: `/service?t=${val}`
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  @mixin dfsb{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .main {
    .header{
      @include dfsb;

      .navs{
        font-size: 14px;
        padding: 25px 0;
        width: 200px;
        min-width: 157px;
      }

      .conter{
        width: calc(100% - 221px);
        /*border-bottom: 2px solid #000000;*/
      }
    }

    .body{
      @include dfsb;
      .navs{
        font-size: 14px;
        padding: 25px 0;
        width: 157px;
        min-width: 157px;
        border-top: 1px solid #000000;
        padding: 28px 0 0;

        .nav{
          margin-bottom: 20px;

          .pop{
            color: #4C4C4C;
            font-size: 14px;
            font-weight: 600;
          }

          .child{
            .btn{
              color: #4C4C4C;
              margin-top: 9px;

              &.sel{
                color: #FF8178;
              }
            }
          }

        }
      }

      .conter{
        width: calc(100% - 221px);
        border-top: 1px solid #000000;
        padding: 27px 144px 30px 0;

        .title{
          font-size: 17px;
          color: #333333;
          font-weight: bold;
          font-family: STSongti-SC-Bold, STSongti-SC;
          margin-bottom: 17px;
        }

        .items{
          margin-bottom: 27px;

          .th{
            margin-bottom: 6px;
            color: #333333;
            font-size: 14px;
            font-weight: 600;
          }

          .td{
            color: #4C4C4C;
            font-size: 14px;
            margin: 5px 0;
            line-height: 24px;
          }

          .tarea{
            margin-top: 27px;
            text-indent:28px;
            color: #4C4C4C;
          }

          img{
            margin-top: 27px;
            max-width: 80%;
          }
        }
      }
    }
  }
</style>
